import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useRouter } from "next/router";
import { Result, Button } from "antd";
const Custom404 = () => {
  const router = useRouter();
  const locale = router.locale || "en-US";
  const intl = useIntl();
  const [path, setPath] = useState<string>("/");
  useEffect(() => {
    setPath(`/${locale}/${router?.query?.to || ""}`);
  }, [router]);
  const goHome = () => {
    window.location.href = path;
  };
  return (
    <>
      <Result
        status="404"
        title="404"
        subTitle={intl.formatMessage({ id: "404.subTitle" })}
        extra={
          <Button onClick={goHome} type="primary">
            {/* Back Home */}
            {intl.formatMessage({
              id: router?.query?.to ? `404.BackCocreate` : "404.Back",
            })}
          </Button>
        }
      />
    </>
  );
};
export default Custom404;
